import { Menu } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { DOMAIN_CONFIG_OBJ } from "../../../../utils/axiosInstance";
import { useAppSelector } from "../../../../reduxStore/hooks";
import { QBOOK_DOMAIN_VALUES } from "../../../../utils/constants/domainNameValues";
import { useNavigate } from "react-router-dom";
import { subValues } from "../../../../utils/constants/subValues";
import { LockOutlined } from "@mui/icons-material";
import SubRedirectionModal from "../../../pages/learn/components/SubRedirectionModal";

type Props = {};
const NavigationManager = (props: Props) => {
  const navigate = useNavigate();
  const { navigationArray } = useAppSelector((state) => state.navigation);
  const { isDark } = useAppSelector((state) => state.theme);
  const { billing } = useAppSelector((state) => state.auth);

  const [openSubModal, setOpenSubModal] = useState(false);

  const handleClick = () => {
    navigate("/");
  };

  const handleOpenSubModal = useCallback(() => {
    setOpenSubModal((pre) => !pre);
  }, []);

  const isAuthorized = useMemo(
    () => Object.values(subValues).includes(billing?.billing?.activePlanName?.toLowerCase() || ""),
    [billing]
  );

  const modifiedNavigationArray = useMemo(() => {
    return navigationArray.map((item: any, index: number) => {
      const isLocked = !isAuthorized && index > 1;

      // Handle items with children (submenus)
      if (item.children) {
        return {
          ...item,
          // disabled: isLocked,
          label: item.label,

          icon: isLocked ? <LockOutlined /> : item.icon,
          children: item.children.map((child: any) => ({
            ...child,
            disabled: isLocked,
            label: isLocked ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenSubModal();
                }}
                style={{
                  width: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
              >
                {child.label}
              </div>
            ) : (
              child.label
            ),
          })),
        };
      }

      // Handle regular menu items
      return {
        ...item,
        disabled: isLocked,
        label: isLocked ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleOpenSubModal();
            }}
            style={{
              width: "100%",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              textAlign: "left",
            }}
          >
            {item.label}
          </div>
        ) : (
          item.label
        ),
        icon: isLocked ? <LockOutlined /> : item.icon,
      };
    });
  }, [navigationArray, isAuthorized, handleOpenSubModal]);

  const handleNavClick = (e: any) => {
    const clickedIndex = navigationArray.findIndex(
      (item: any) => item.key === e.key
    );
    if (!isAuthorized && clickedIndex > 1) {
      handleOpenSubModal();
      return;
    }

    const userKey = e.key;
    if (!userKey) {
      return;
    }
    navigate(`/learn/${userKey}`);
  };
  return (
    <div className="navigation-menus">
      <div
        className="navigation-logo-container"
        onClick={() => handleClick()}
        cy-data="navigation-logo-container"
      >
        <img
          src={
            DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
              ? isDark
                ? DOMAIN_CONFIG_OBJ.darkThemeLogo
                : DOMAIN_CONFIG_OBJ.lightThemeLogo
              : DOMAIN_CONFIG_OBJ.simpleLogo
          }
          className={
            DOMAIN_CONFIG_OBJ.domainName !== QBOOK_DOMAIN_VALUES.quera
              ? "navigation-logo"
              : "navigation-logo-quera"
          }
          alt="navigation"
          cy-data="navigation-logo"
        />
        {DOMAIN_CONFIG_OBJ.domainName !== QBOOK_DOMAIN_VALUES.quera && (
          <span className="navigation-title">
            {DOMAIN_CONFIG_OBJ.domainName}
          </span>
        )}
      </div>
      <div className="navigation-menu-container">
        <Menu
          mode={"horizontal"}
          items={modifiedNavigationArray}
          style={{
            backgroundColor: "var(--bg-primary-clr1)",
            color: "var(--text-primary-clr1)",
            border: "none",
            maxWidth: "80%",
          }}
          onClick={handleNavClick}
          onSelect={(e) => console.log(e)}
        />
      </div>
      <SubRedirectionModal
        open={openSubModal}
        handleClose={handleOpenSubModal}
      />
    </div>
  );
};

export default NavigationManager;
