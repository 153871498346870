import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

type Props = {};

export const LoadingCourseCard = (props: Props) => {
  return (
    <Grid item container xs={12} sm={12} md={6} lg={4} padding={1}>
      <Grid
        item
        container
        sx={{
          overflow: "hidden",
          backgroundColor: "var(--landing-course-card-bg)",
          borderRadius: "15px",
          boxShadow: 3,
          minHeight: "200px",
          
          cursor: "pointer",
          ":hover": {
            border: "2px solid var(--landing-course-card-hover-border)"
          }
        }}
      >
        <Grid item xs={12} minHeight={100}>
          {" "}
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexDirection: "column", gap: "1em",padding: "15px", }}
        >
          <Skeleton variant="rounded" width="100%" height={20} />
          <Skeleton variant="rounded" width="100%" height={20} />
          <Skeleton variant="rounded" width="100%" height={20} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const LoadingProfileCard = (props: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={5.5}
      lg={3.5}
      gap={2}
      sx={{
        backgroundColor: "var(--landing-course-card-bg)",
        borderRadius: "15px",
        boxShadow: 3,
        minHeight: "200px",
        padding: "15px",
        position: "relative",
        cursor: "pointer",
        ":hover": {
          border: "2px solid var(--landing-course-card-hover-border)"
        }
      }}
    >
      <Box width="20%">
        <Skeleton variant="circular" width={80} height={80} />
      </Box>
      <Box
        width="70%"
        height="100%"
        display="flex"
        flexDirection="column"
        gap="1.5em"
      >
        <Skeleton variant="text" width="90%" height={60} />
        <Skeleton variant="text" width="100%" height={20} />
        <Box sx={{ display: "flex", gap: "1em", width: "100%" }}>
          <Skeleton variant="rounded" width="20%" height={20} />
          <Skeleton variant="rounded" width="20%" height={20} />
        </Box>
        <Box sx={{ display: "flex", gap: "1em", width: "100%" }}>
          <Skeleton variant="rounded" width="20%" height={20} />
          <Skeleton variant="rounded" width="20%" height={20} />
        </Box>
      </Box>
    </Grid>
  );
};

export const LoadingBlogCard = (props: Props) => {
  return (
    <Grid item container xs={12} sm={12} md={12} lg={6} padding={1}>
      <Grid
        item
        container
        sx={{
          overflow: "hidden",
          backgroundColor: "var(--landing-course-card-bg)",
          borderRadius: "15px",
          boxShadow: 3,
          minHeight: "200px",
          
          cursor: "pointer",
          ":hover": {
            border: "2px solid var(--landing-course-card-hover-border)"
          }
        }}
      >
        <Grid item xs={4}>
          {" "}
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Grid>
        <Grid
          item
          xs={8}
          sx={{ display: "flex", flexDirection: "column", gap: "1em",padding: "15px", }}
        >
          <Skeleton variant="rounded" width="100%" height={20} />
          <Skeleton variant="rounded" width="100%" height={20} />
          <Skeleton variant="rounded" width="100%" height={20} />
        </Grid>
      </Grid>
    </Grid>
  );
};
