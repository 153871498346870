/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useCallback, useEffect, useMemo } from "react";
import { ThemeProvider } from "@mui/material";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../reduxStore/hooks";
import { useBeforeRender } from "./hooks/useBeforeRender";
import {
  fetchUser,
  getUserBillingAction,
  getUserIdentityAction,
} from "../reduxStore/middleware/authMiddleware";
import { toggleTheme } from "../reduxStore/features/themeSlice";
import { QBOOK_DOMAIN_VALUES } from "../utils/constants/domainNameValues";
import { DOMAIN_CONFIG_OBJ } from "../utils/axiosInstance";

// Component imports
import Auth from "./pages/login";
import Layout from "./pages/layout/index";
import AdminLayout from "./pages/Admin";
import CourseDashBoard from "./pages/Admin/components/course";
import BlogDashBoard from "./pages/Admin/components/blog";
import ErrorBoundaryPage from "./pages/errorPage";
import Loading from "./components/loading";
import StyledSnackbar from "./components/Snackbar";
import { darkTheme, lightTheme } from "../utils/MUITheme/theme";
import Landing from "./pages/landingPage";
import EditProfile from "./pages/editProfile";
import OpenReplay from "./components/OpenReplay";
import CookieConsent from "./components/CookieConsent";

// Lazy-loaded components
const Learn = React.lazy(() => import("./pages/learn/index"));
const Oauth = React.lazy(() => import("./pages/login/OAuthLogin"));
const Blogs = React.lazy(() => import("./pages/blogs"));
const Termly = React.lazy(() => import("./pages/legal"));

function App() {
  const dispatch = useAppDispatch();
  const { isDark } = useAppSelector((state) => state.theme);
  const {
    isAuthenticated,
    userData,
    isLoading: userLoading,
  } = useAppSelector((state) => state.auth);

  useBeforeRender(() => {
    const handleError = (e: ErrorEvent) => {
      const resizeObserverErrDiv = document.getElementById(
        "webpack-dev-server-client-overlay-div"
      );
      const resizeObserverErr = document.getElementById(
        "webpack-dev-server-client-overlay"
      );
      if (resizeObserverErr)
        resizeObserverErr.className = "hide-resize-observer";
      if (resizeObserverErrDiv)
        resizeObserverErrDiv.className = "hide-resize-observer";
    };
    window.addEventListener("error", handleError);
    return () => window.removeEventListener("error", handleError);
  }, []);

  const initialLoader = useCallback(async () => {
    if (window.location.pathname !== "/oauth") {
      const result = await dispatch(fetchUser(true));
      if (result.type === "auth/fetchUser/fulfilled") {
         await dispatch(getUserIdentityAction());
         await dispatch(getUserBillingAction());
      }
    }
  }, [dispatch]);

  const handleFocus = useCallback(() => dispatch(fetchUser(false)), [dispatch]);

  useEffect(() => {
    initialLoader();
    // focus event is invoked in prod or staging only, not in dev
    const isDevelopment = process.env.REACT_APP_ENV === "dev";
    if (!isDevelopment) {
      window.addEventListener("focus", handleFocus);
      return () => window.removeEventListener("focus", handleFocus);
    }
  }, [dispatch, handleFocus]);

  useEffect(() => {
    const html = document.querySelector("html");
    if (DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera) {
      html?.toggleAttribute("data-quera-mode", true);
    } else if (DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qusteam) {
      html?.toggleAttribute("data-qusteam-mode", true);
    }
    const themeValue = localStorage.getItem("qbookTheme");
    if (themeValue === "dark") {
      dispatch(toggleTheme(true));
      html?.toggleAttribute("data-dark-mode", true);
    }
  }, [dispatch]);

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/",
          element: (
            <Suspense fallback={<Loading />}>
              <Layout />
            </Suspense>
          ),
          errorElement: <ErrorBoundaryPage />,
          children: [
            { index: true, element: <Landing /> },
            { path: "learn/*", element: <Learn /> },
            { path: "blogs/*", element: <Blogs /> },
            { path: "blog/*", element: <Blogs /> },
            {
              path: "profile/*",
              element: userLoading ? (
                <Loading />
              ) : !isAuthenticated ? (
                <Navigate to="/login" />
              ) : (
                <EditProfile />
              ),
            },
            {
              path: "terms-and-conditions",
              element: <Termly dataId="bd885a1a-cf4d-4cfd-bc3c-eb383bab2afe" />,
            },
            {
              path: "privacy-policy",
              element: <Termly dataId="aab5c6d8-8f23-4de0-bf15-093b9363690b" />,
            },
            {
              path: "cookie-policy",
              element: <Termly dataId="b91f6ee2-e92a-46be-b75d-4d2f0d897ab3" />,
            },
            { path: "*", element: <Navigate to="/" /> },
          ],
        },
        {
          path: "/admin",
          element: (
            <Suspense fallback={<Loading />}>
              {userLoading ? (
                <Loading />
              ) : !isAuthenticated ? (
                <Navigate to="/login" />
              ) : (
                <AdminLayout />
              )}
            </Suspense>
          ),
          errorElement: <ErrorBoundaryPage />,
          children: [
            { index: true, element: <CourseDashBoard /> },
            { path: "courses/*", element: <CourseDashBoard /> },
            { path: "blogs/*", element: <BlogDashBoard /> },
            { path: "*", element: <Navigate to="/admin" /> },
          ],
        },
        {
          path: "/login/*",
          element: (
            <Suspense fallback={<Loading />}>
              {!isAuthenticated ? <Auth /> : <Navigate to="/" />}
            </Suspense>
          ),
          errorElement: <ErrorBoundaryPage />,
        },
        {
          path: "/oauth",
          element: (
            <Suspense fallback={<Loading />}>
              <Oauth />
            </Suspense>
          ),
          errorElement: <ErrorBoundaryPage />,
        },
        {
          path: "*",
          errorElement: <ErrorBoundaryPage />,
          element: <Navigate to="/" />,
        },
      ]),
    [isAuthenticated, userData, userLoading]
  );
  const theme = useMemo(() => (isDark ? darkTheme : lightTheme), [isDark]);
  return (
    <ThemeProvider theme={theme}>
      <OpenReplay>
        <CookieConsent positionX="left" positionY="bottom" />
        <RouterProvider router={router} />
        <StyledSnackbar />
      </OpenReplay>
    </ThemeProvider>
  );
}

export default App;
